@import "./fonts";
@import './grid';
@import './variables';

body {
  margin: 0;
  font-family: $font-main-light;
  line-height: 25px;
}

input,
textarea {
  font-family: $font-main-light;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $color-main;
  }
}

img {
  width: 100%;
}

h1 {
  margin-top: 0;
  margin-bottom: 50px;
  font-family: $font-secondary-regular;
  font-size: 30px;
  line-height: 38px;
}

.ReactModal__Overlay {
  z-index: 1050;

  .ril-inner.ril__inner img {
    width: inherit;
  }
}

.hide-desktop {
  display: none;
}

@media (max-width: $xs) {
  h1 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  .hide-desktop {
      display: block;
  }

  .hide-mobile {
    display: none;
  }
}


