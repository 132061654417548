@import '@styles/variables';

.footer-component {
  & {
    width: 100%;
    background-color: $color-main;
    color: $color-white;
    padding: 30px 0;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
  }

  &-item {
    width: 33.333%;
    text-align: center;
    border-right: 1px solid #ffffff;

    &:last-child {
      border-right: none;
    }
  }

  &-item-title {
    margin-bottom: 10px;

  }

  &-item-content {
    font-family: $font-secondary-bold;
  }

  &-social-wrapper {
    & {
      text-align: center;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .social-icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0 10px;
    }

    .facebook-icon {
      background: url("../../../../static/img/icons/social/facebook-white.svg") no-repeat;
      background-size: 23px 23px;
      width: 23px;
      height: 23px;

      &:hover {
        background: url("../../../../static/img/icons/social/facebook.svg");
      }
    }

    .instagram-icon {
      background: url("../../../../static/img/icons/social/instagram-white.svg") no-repeat;
      background-size: 23px 23px;
      width: 23px;
      height: 23px;

      &:hover {
        background: url("../../../../static/img/icons/social/instagram.svg");
      }
    }

    .vk-icon {
      background: url("../../../../static/img/icons/social/vk-white.svg") no-repeat;
      background-size: 30px 30px;

      &:hover {
        background: url("../../../../static/img/icons/social/vk.svg");
      }
    }

    .youtube-icon {
      background: url("../../../../static/img/icons/social/youtube-white.svg") no-repeat;
      background-size: 30px 30px;

      &:hover {
        background: url("../../../../static/img/icons/social/youtube.svg");
      }
    }

    img {
      height: 30px;
      width: auto;
      padding: 0 10px;
      cursor: pointer;
    }

    img:hover {
      transform: scale(1.1);
    }
  }

}
